<template>
    <div class="pt-3 justify-center d-flex flex-column">
        <v-menu offset-y>
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            height="36px"
                            small
                            v-on="{...tooltip, ...menu}"
                        >
                            <v-icon small dark v-on>ti ti-export</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('message.export') }}</span>
                </v-tooltip>
            </template>
            <v-list dense>
                <v-list-item @click="exportTable('pdf')">
                    <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportTable('pdf', true)">
                    <v-list-item-title>{{ $t('message.exportAsPdfWithoutSupplier') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportTable('excel')">
                    <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {api} from "Api";

    export default {
        name: "ExportTableProduct",
        props: ['exportName','exportData','exportFields','exportConditions','exportSource'],
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            })
        },
        methods: {
            async exportTable(reportType, noSupplier = false) {
                let date = new Date()
                let day = date.getDate();
                let month = date.getMonth()+1;
                let year = date.getFullYear();
                let fullDate = year + "-" + month + "-" + day

                let reportFileExtension = '.pdf'
                let reportFileType = 'application/pdf'
                if(reportType == 'excel'){
                    reportFileExtension = '.xlsx'
                    reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
                if (this.exportData.length > 0 || typeof this.exportData === 'object') {
                    try {
                        this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                            classes: ['text--white'],
                            color: 'info',
                            timeout: 0,
                            icon: 'check',
                            iconColor: 'white',
                            x: 'center',
                            y: 'top'
                        })

                        const apiEndpoint = '/products/suppliers/export'
                        const reportFileName = 'Suppliers_Products_List_' + fullDate
                        let data = []
                        let fields = []
                        if(noSupplier){
                            this.exportFields.forEach(field => {
                                if(field.value != 'Product.supplier'){
                                    fields.push(field)
                                }
                            })
                            this.exportData.forEach(item => {
                                let itemCopy = _.cloneDeep(item)
                                delete itemCopy['Product']['supplier']
                                data.push(itemCopy)
                            })
                        } else {
                            data = this.exportData
                            fields = this.exportFields
                        }

                        let exportedFile = await api.post(apiEndpoint, {
                            conditions: this.exportConditions,
                            data: data,
                            fields: fields,
                            type: reportType
                        }, {
                            responseType: 'blob'
                        })

                        const url = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = '_blank'
                        link.setAttribute('download', reportFileName + reportFileExtension);
                        link.click();
                        link.remove();
                        window.URL.revokeObjectURL(url);
                        //close notification
                        let cmp = this.$toast.getCmp()
                        cmp.close()
                    } catch (error) {
                        this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                            timeout: 7000,
                            x: 'center',
                            y: 'top'
                        })
                    }
                } else {
                    alert(this.$t('message.noDataToExport'))
                }
            }
        }
    }
</script>

<style>
.v-snack__content {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
</style>